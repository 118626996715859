import React from "react";
import styles from "./styles.module.css";
import SinglePlan from "./SinglePlan";
import {
  cardBg1,
  cardBg2,
  cardBg3,
  cardBg4,
  cardBg5,
  cardBg6,
} from "../../../images/image";
import { useState, useEffect } from "react";
import axios from "axios";

const InvestmentPlans = () => {
  const allPlans = [];

  const [plans, setPlans] = useState([]);
  let api = process.env.REACT_APP_API_URL;
  let user_id = localStorage.getItem("auth-token");

  async function getPlans() {
    let { data } = await axios.get(api + "/user/plans", {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + user_id,
      },
      
    });
    console.log(data)
    setPlans(data);
  }
  useEffect(() => {
    getPlans();
  }, []);
  return (
    <div className="homeWrapper">
      <div className={styles.wrapper}>
        <h2 className={styles.heading}>Support Plans</h2>
        <p className={styles.subHeading}>
        YOUR CONTRIBUTION TO SUPPORT OUR INNOVATIVE GROWTH IN THE INDUSTRY <br />
          {/* <span className={styles.subText}>Staken Sie Ihre Kryptowährungen und profitieren Sie von unseren automatisierten Investitionsstrategien.</span> */}
        </p>

        <div className={styles.plans}>
          {plans.map((el, i) => (
            <SinglePlan {...el} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default InvestmentPlans;
