import React, { useEffect } from "react";
import { BsArrowUpShort } from "react-icons/bs";

import styles from "./Sidebar.module.css";
import {
  dashboard,
  myStakes,
  transaction,
  plans,
  profile,
  contact,
  activeDashboard,
  activeTransaction,
  activePlans,
  activeProfile,
  leaderBoard,
  activeLeaderBoard,
  investment,
  activeInvestment,
  activeContact,
} from "../../../images/image";
import { NavLink } from "react-router-dom";

const Sidebar = ({ sidebar, setSidebar, userInfo }) => {
  const navItems = [
    {
      icons: [dashboard, activeDashboard],
      navItem: "Dashboard",
      to: "/dashboard",
    },
    { icons: [plans, activePlans], navItem: "Investment Plans", to: "/plans" },
    // { icons: [plans, activePlans], navItem: "My Portfolio", to: "/portfolio" },

    // {
    //   icons: [transaction, activeTransaction],
    //   navItem: "Transactions",
    //   to: "/transaction",
    // },
    {
      icons: [investment, activeInvestment],
      navItem: "Investment History",
      to: "/history",
    },

    {
      icons: [investment, activeInvestment],
      navItem: "Transaction History",
      to: "/deposit-history",
    },

    // {
    //   icons: [myStakes, activeTransaction],
    //   navItem: "My Investments",
    //   to: "/investments",
    // },

    // {
    //   icons: [leaderBoard, activeLeaderBoard],
    //   navItem: "Leaderboard",
    //   to: "/leaderboard",
    // },
    // { icons: [profile, activeProfile], navItem: "Upload KYC for Insurance Plans", to: "https://forms.gle/DJXW2PErCcUR7Q4F9" },
    { icons: [profile, activeProfile], navItem: "My Profile", to: "/profile" },

  ];


  const formatNumber = (number) =>
    number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  let uns_share = userInfo?.uns_balance;
  let balance = userInfo?.balance;
  return (
    <section
      className={[styles.sidebar, sidebar && styles.showSidebar].join(" ")}
    >
      <div className={styles.info}>
        <div className={styles.balanceContainer}>
          <p className={styles.navHeading}>MY BALANCE</p>
          <h4 className={styles.balance}>
            {userInfo?.balance} <span className={styles.currency}></span>
          </h4>
          {/* <p className={styles.inUsd}>4000 USD</p> */}
        </div>
        <div className={styles.profitDeprsitInfo}>

          <div className={styles.spaceBetween}>
            <p className={styles.key}>USD Balance</p>
            <p className={styles.value}>
              {(balance)}{" "}
              <span className={styles.valueCurrency}>USD</span>
            </p>
          </div>
          {/* <div className={styles.spaceBetween}>
            <p className={styles.key}>UNS Balance</p>
            <p className={styles.value}>
              {(balance - uns_share)}{" "}
              <span className={styles.valueCurrency}>USD</span>
            </p>
          </div> */}
          <div className={styles.spaceBetween}>
            <div className={styles.key}>
              <p> Total Investment  </p>
              <p className={styles.profit}>
                {/* <span></span> <BsArrowUpShort className={styles.up} /> */}
              </p>
            </div>
            <p className={styles.value}>
              {userInfo?.total_investments
                ? userInfo.total_investments / Math.pow(10, 8)
                : "0"}{" "}
              <span className={styles.valueCurrency}>USD</span>
            </p>



          </div>
          {/* <div className={styles.spaceBetween}>
            <p className={styles.key}>Withdraw in progress</p>
            <p className={styles.value}>
              {formatNumber(1.0)}{" "}
              <span className={styles.valueCurrency}>USD</span>
            </p>
          </div> */}
          <div className={styles.buttonContainer}>
            <button
              onClick={() => {
                window.location.href = "/portfolio"
              }}
              className={[styles.button, styles.deposit].join(" ")}>
              Deposit
            </button>
            <button
              onClick={() => {
                window.location.href = "/portfolio"
              }}
              className={[styles.button, styles.withdraw].join(" ")}>
              Withdraw
            </button>
          </div>
        </div>
      </div>
      <div className={styles.navContainer}>
        {" "}
        <div>
          <p className={styles.navHeading}>Menu</p>
          <div className={styles.navItems}>
            {navItems.map((el, i) => (
              <NavLink
                to={el.to}
                key={i}
                className={({ isActive }) =>
                  isActive
                    ? [styles.navItem, styles.navActive].join(" ")
                    : styles.navItem
                }
                onClick={() => setSidebar((prev) => !prev)}
              >
                {/* {({ isActive, isPending }) => {
                  isActive ? (
                    <img src={el.icons[0]} alt="#" className={styles.navIcon} />
                  ) : (
                    <img src={el.icons[1]} alt="#" className={styles.navIcon} />
                  )
                }} */}
                {({ isActive, isPending }) => {
                  return (
                    <>
                      <img
                        src={isActive ? el.icons[1] : el.icons[0]}
                        alt="#"
                        className={styles.navIcon}
                      />
                      <span>{el.navItem}</span>
                    </>
                  );
                }}
              </NavLink>
            ))}
          </div>
        </div>
        {/* <div>
          <p className={styles.navHeading}>Additional</p>
          <NavLink
            to="/home"
            className={({ isActive }) =>
              isActive
                ? [styles.navItem, styles.navActive].join(" ")
                : styles.navItem
            }
            onClick={() => setSidebar((prev) => !prev)}
          >
            Home
          </NavLink>
        </div> */}
      </div>{" "}
      <NavLink
        to={`${process.env.REACT_APP_APP_MAIL}`}
        className={({ isActive }) =>
          [
            styles.navItem,
            styles.contactTab,
            isActive && styles.navActive,
          ].join(" ")
        }
        onClick={() => setSidebar((prev) => !prev)}
      >
        {({ isActive, isPending }) => {
          return (
            <>
              <img
                src={isActive ? activeContact : contact}
                alt="#"
                className={styles.navIcon}
              />
              <span>Contact us</span>
            </>
          );
        }}
      </NavLink>
    </section>
  );
};

export default Sidebar;
