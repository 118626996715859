import React, { useState } from "react";
import styles from "./ContactUs.module.css";
import { contactUs } from "../../../images/image";

const ContactUs = () => {
  const [values, setValues] = useState({
    category: "",
    origin: "",
    size: "",
    stateoftheassest: "",
  });
  const [message, setMessage] = useState("");

  const inputs = [
    {
      label: "Full Name*",
      type: "text",
      name: "fullName",
      required: true,
    },
    {
      label: "Last name*",
      type: "text",
      name: "lastName",
      required: true,
    },
    {
      label: "Phone number",
      type: "tel",
      name: "phoneNumber",
    },
    {
      label: "Email address",
      type: "eamil",
      name: "eamil",
    },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className={["homeWrapper", styles.wrapper].join(" ")}>
      <form onSubmit={submitHandler} className={styles.form}>
        {inputs.map((input, i) => (
          <div className={styles.inputContainer} key={i}>
            <label
              htmlFor={input.name}
              className={`${styles.label} ${styles.text}`}
            >
              {input.label}
            </label>
            <input
              required={input.required}
              type={input.type}
              id={input.name}
              name={input.name}
              value={values[input.name]}
              className={`${styles.input} ${styles.text}`}
              placeholder={input.placeholder}
              onChange={onChange}
            />
          </div>
        ))}
        <div className={styles.messageContainer}>
          <label htmlFor="message" className={`${styles.label} ${styles.text}`}>
            Message
          </label>
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="4"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={styles.textArea}
          ></textarea>
        </div>{" "}
        <button type="submit" className={styles.button}>
          Submit
        </button>
      </form>
      <img src={contactUs} alt="#" className={styles.image} />
    </div>
  );
};

export default ContactUs;
