import React from "react";
import styles from "./TrustedClients.module.css";

const TrustedClients = () => {
  return (
    <div className={styles.wrapper}>
      <div className={[styles.container, "homeWrapper"].join(" ")}>
        <div className={styles.textContainer}>
          <p className={styles.tagline}>Did you know that</p>
          <h3 className={styles.title}>
            We are trusted by more than 5,000 clients
          </h3>
          <p className={styles.info}>
            artificial intelligence and blockchain, we are dedicated to
            unlocking
          </p>
          <button className={styles.button}>Read Our Story</button>
        </div>
        <div className={styles.overlay}></div>
      </div>
    </div>
  );
};

export default TrustedClients;
