import React from "react";
import formatNumber from "../../../utils";
import styles from "./SinglePlan.module.css";

const SinglePlan = ({
  image,
  id,
  investedAmount,
  startDate,
  endDate,
  totalReturn,
}) => {
  return (
    <div className={styles.singlePlan}>
      <div className={styles.imageAndId}>
        <img src={image} alt="#" className={styles.planImage} />
        <div className={styles.idAndInvestedAmount}>
          <p className={styles.text}>ID :{id.split("-")[0]}</p>
          <p className={[styles.text, styles.secondaryText].join(" ")}>
            Invested:{" "}
            <span className={styles.amount}>
              {" "}
              {formatNumber(investedAmount)}
            </span>{" "}
            <span className={styles.currency}>USD</span>
          </p>
        </div>
      </div>
      <div className={styles.allBoxs}>
        <div className={styles.box}>
          <p className={styles.text}>{startDate}</p>
          <p className={styles.secondaryText}>Start Date</p>
        </div>{" "}
        <div className={styles.box}>
          <p className={styles.text}>{endDate}</p>
          <p className={styles.secondaryText}>End Date</p>
        </div>
        <div className={[styles.box, styles.endDate].join(" ")}>
          <p className={styles.text}> {(totalReturn)} %</p>
          <p className={styles.secondaryText}>Reward %</p>
        </div>
        <div className={styles.buttonContainer}>
          <button
            // open investments and pass in id  to url
            onClick={() => window.open(`/investments/${id}`)}

            className={styles.manageButton}>MANAGE</button>
        </div>
      </div>
    </div>
  );
};

export default SinglePlan;
