import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";

import { home1Logo } from "../../../images/image";

import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

const Footer = () => {
  const socialItems = [
    { icon: <FaFacebookF className={styles.socialIcon} />, to: "#" },
    { icon: <FaInstagram className={styles.socialIcon} />, to: "#" },
    { icon: <FaTwitter className={styles.socialIcon} />, to: "#" },
  ];

  const footerItems = [
    {
      heading: "Our services",
      items: [
        { item: "Strategy & Research", to: "Staratgy&Research" },
        // { item: "Web Development", to: "/WebDevelopment" },
        // { item: "Web Solution", to: "/WebSolution" },
        // { item: "Digital marketing", to: "/Digitalmarketing" },
        // { item: "App Design", to: "/App Design" },
        // { item: "App Development", to: "/App Development" },
      ],
    },
    {
      heading: "Company",
      items: [
        { item: "About us", to: "/Aboutus" },
        // { item: "Services", to: "/Services" },
        // { item: "Project", to: "/Project" },
        // { item: "Blog", to: "/Blog" },
        // { item: "Career", to: "/Career" },
        // { item: "Price Plain", to: "/PricePlain" },
      ],
    },
  ];
  return (
    <div className={styles.footer}>
      <div className="homeWrapper">
        <footer className={[styles.wrapper].join(" ")}>
          <div className={styles.logoAndInfo}>
            <img src={home1Logo} alt="#" className={styles.logo} />

            <p className={styles.info}>
            {process.env.REACT_APP_APP_NAME} a cutting-edge investment Solution at the forefront of AI
              and blockchain innovation. Our name reflects our
              <br />
              commitment to harnessing the power of technology.
            </p>
          </div>
          {footerItems.map((el, i) => (
            <div className={styles.itemContainer} key={i}>
              <h4 className={styles.heading}>{el.heading}</h4>

              {el.items.map((items, index) => (
                <Link to={items.to} key={index} className={styles.item}>
                  {items.item}
                </Link>
              ))}
            </div>
          ))}
          <div className={styles.itemContainer}>
            <h4 className={styles.heading}>Contact Us</h4>
            <a href={process.env.REACT_APP_EMAIL} className={styles.text}>
              {process.env.REACT_APP_EMAIL}
            </a>
            {/* <p className={styles.text}>House#168/Danyore</p> */}
            {/* <p className={styles.text}>+9203554403587</p>{" "} */}
            <div className={styles.socialContainer}>
              {socialItems.map((social, i) => (
                <a
                  href={social.to}
                  target="_blank"
                  rel="noreferrer"
                  key={i}
                  className={styles.iconContainer}
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>
        </footer>
        <div className={styles.footerBottom}>
          <p className={styles.copyRight}>
            © 2023 Copyright by {process.env.REACT_APP_APP_NAME}. All rights reserved.
          </p>
          <div className={styles.linkContainer}>
            <a
              href="#/"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              Privacy
            </a>{" "}
            <a
              href="#/"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              Terms of use
            </a>{" "}
            <a
              href="#/"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              Support Policy
            </a>{" "}
            <a
              href="#/"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              terms of Service
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
