import React from "react";
import styles from "./GetTheUpdate.module.css";

const GetTheUpdate = () => {
  return (
    <section className={styles.mainWrapper}>
      <div className={["homeWrapper", styles.wrapper].join(" ")}>
        <div className={styles.container}>
          <h4 className={styles.title}>Get The Update</h4>
          <div className={styles.inputContainer}>
            <label htmlFor="email" className={styles.label}>
              Type your Email :
            </label>
            <input rows={1} id="email" type="text" className={styles.email} />
            <div className={styles.buttonContainer}>
              <button className={styles.button}>Send now</button>
            </div>
          </div>
          <p className={styles.info}>
            Sign up to receive the best offers on promotion and coupons.
          </p>
        </div>
      </div>
    </section>
  );
};

export default GetTheUpdate;
