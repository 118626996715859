import React, { useState } from "react";
import HeroSection from "../../Component/Home1/HeroSection/HeroSection";
import Facilities from "../../Component/Home1/Facilities/Facilities";
import Services from "../../Component/Home1/Services/Services";

import AboutUs from "../../Component/Home1/AboutUs/AboutUs";
import TrustedClients from "../../Component/Home1/TrustedClients/TrustedClients";
import styles from "./Home1.module.css";
import ExpertField from "../../Component/Home1/ExpertField/ExpertField";
import ContactUs from "../../Component/Home1/ContactUs/ContactUs";
import GetTheUpdate from "../../Component/Home1/GetTheUpdate/GetTheUpdate";
import Footer from "../../Component/Home1/Footer/Footer";
import InvestmentPlans from "../../Component/Home1/InvestmentPlans/InvestmentPlans";
import Header from "../../Component/Home1/Header/Header";
import ScrollToTop from "../../Component/Home1/ScrollToTopAndBottom/ScrollToTopAndBottom";
import ScrollToBottom from "../../Component/Home1/ScrollToTopAndBottom/ScrollToBottom";

const Home1 = () => {
  const [dropDown, setDropDown] = useState(false);
  return (
    <div className={styles.wrapper}>
      <Header dropDown={dropDown} setDropDown={setDropDown} />
      <HeroSection />
      <Facilities />
      <Services />
      <AboutUs />
      <TrustedClients />
      <ExpertField />
      <InvestmentPlans />
      <ContactUs />
      <GetTheUpdate />
      <Footer />
      {!dropDown && <ScrollToTop />}
      <ScrollToBottom />
    </div>
  );
};

export default Home1;
