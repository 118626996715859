import React from "react";
import styles from "./Portfolio.module.css";
import Heading from "../../Component/Portfolio/Heading/Heading";
import Chart from "../../Component/Portfolio/Chart/Chart";
import InfoTable from "../../Component/Portfolio/InfoTable/InfoTable";
import TokenAllocation from "../../Component/Portfolio/TokenAllocation/TokenAllocation";
import PendingRewards from "../../Component/Portfolio/PendingRewards/PendingRewards";
import { WithdrawBalance } from "../../Component/OurPlans/Popup/TransferBalance";
import { useState } from "react";
const Portfolio = ({ userInfo }) => {
  const enableTransfer = (token, id) => {
    localStorage.setItem("withdraw-token", token);
    localStorage.setItem("withdraw-token-id", id);
    setTransferFunds(true);
  };
  const [transferFunds, setTransferFunds] = useState(false);
  return (
    <>
      <Heading />
      <div className={styles.wrapper}>
        {/* <Chart /> */}
        <div className={styles.rightSide}>
          {/* <TokenAllocation /> */}
        </div>
      </div>
      <div className={styles.forMobileAndTab}>
        {" "}
        {/* <TokenAllocation /> <PendingRewards /> */}
      </div>
      <div className={styles.wrapper}>
        <InfoTable enableTransfer={enableTransfer} />
        <div className={styles.rightSide}>

        </div>
      </div>
      {transferFunds && <WithdrawBalance userInfo={userInfo} setPopup={setTransferFunds} />}
    </>
  );
};

export default Portfolio;
