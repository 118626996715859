import React from "react";
import styles from "./Facilities.module.css";
import { clock, time, user } from "../../../images/image";

const Facilities = () => {
  const facilities = [
    {
      icon: time,
      title: "On Time Service",
      text: "We understand the value of your time, and we're dedicated to providing you with top-notch services that prioritize punctuality and efficiency.",
    },
    {
      icon: user,
      title: "A Team Of Professionals",
      text: "Our team is dedicated to delivering the best results, and we continually invest in their training and development to stay ahead in the industry. ",
    },
    {
      icon: clock,
      title: "24/7 Automatic Deposit and Withdrawal",
      text: "Our systems ensures that your deposit and withdrawal requests are processed seamlessly, 24/7.",
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={["homeWrapper", styles.cardWrapper].join(" ")}>
        {facilities.map((el, i) => (
          <div className={styles.card} key={i}>
            <div className={styles.iconContainer}>
              <img src={el.icon} alt="#" className={styles.icon} />
            </div>
            <h4 className={styles.title}>{el.title}</h4>
            <p className={styles.text}>{el.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Facilities;
