import React, { useState } from "react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { AiOutlineAlignRight } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import Sidebar from "./Sidebar/Sidebar";

import { ai, germany, logo, userImage } from "../../images/image";
import styles from "./Header.module.css";

const Header = ({ userInfo }) => {
  const [sidebar, setSidebar] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [language, setLanguage] = useState({
    flag: germany,
    country: "English",
  });
  const allLanguage = [
    // { flag: germany, country: "English" },
    // { flag: germany, country: "German" },
    // { flag: germany, country: "German" },
    // { flag: germany, country: "German" },
  ];
  return (
    <>
      <Sidebar sidebar={sidebar} setSidebar={setSidebar} userInfo={userInfo} />
      <section className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.logoContainer}>
            <img src={process.env.REACT_APP_LOGO} alt="#" className={styles.logo} />
            <h1 className={styles.logoText}>{process.env.REACT_APP_APP_NAME} </h1>
            {/* <img src={ai} alt="#" className={styles.ai} /> */}
          </div>
          <div className={styles.userAndLanguageContainer}>
            <div className={styles.userContainer}>
              <div className={styles.userImagecontainer}>
                <img
                  onClick={() => {
                    localStorage.removeItem("token");
                    window.location.href = "/login";
                  }
                  }
                  src={"https://cdn-icons-png.flaticon.com/128/4043/4043198.png"} alt="#" className={styles.userImage} />
              </div>
              <div className={styles.nameContainer}>
                <p className={styles.name}>{userInfo?.first_name}</p>
                <p className={styles.verified}>{userInfo?.status}</p>
              </div>
            </div>
            {/* <div className={styles.languageContainer}>
              <div className={styles.language}>
                <img src={language.flag} alt="#" className={styles.flag} />

                {dropDown ? (
                  <FiChevronUp
                    className={styles.arrow}
                    onClick={() => setDropDown((prev) => !prev)}
                  />
                ) : (
                  <FiChevronDown
                    className={styles.arrow}
                    onClick={() => setDropDown((prev) => !prev)}
                  />
                )}
              </div>
              {dropDown && (
                <div className={styles.dropDownItems}>
                  {allLanguage.map((el, i) => (
                    <div
                      className={styles.dropdownItem}
                      key={i}
                      onClick={() => {
                        setLanguage(el);
                        setDropDown(false);
                      }}
                    >
                      <img
                        src={el.flag}
                        alt="#"
                        className={styles.countryFlag}
                      />
                      <p className={styles.countryName}>{el.country}</p>
                    </div>
                  ))}
                </div>
              )}
            </div> */}
            {sidebar ? (
              <MdClose
                className={styles.close}
                onClick={() => setSidebar((prev) => !prev)}
              />
            ) : (
              <AiOutlineAlignRight
                className={styles.hamburger}
                onClick={() => setSidebar((prev) => !prev)}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
