import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import styles from "./TokenAllocation.module.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const TokenAllocation = () => {
  const [active, setActive] = useState(0);
  const labels = ["ETC", "MATIC", "BTC", "AVAX", "BNB", "OTHERS"];
  const values = [15, 11, 11, 13, 20, 30];
  const colors = [
    "#FAFF00",
    "#FF55AB",
    "#3EFAC7",
    "#FF6161",
    "#8E5AE7",
    "#48D3FF",
  ];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: values,
        backgroundColor: colors,
        borderColor: [
          "#2A2A2A",
          "#2A2A2A",
          "#2A2A2A",
          "#2A2A2A",
          "#2A2A2A",
          "#2A2A2A",
          "#2A2A2A",
        ],
        borderWidth: 3,
        cutout: "83%",
        borderRadius: [15],
      },
    ],
  };
  const chartOptions = {
    width: "100%",

    // This sets the space between the segments
    plugins: {
      //   doughnutlabel: {
      //     labels: [
      //       {
      //         text: "Votes",
      //         font: {
      //           size: 20,
      //         },
      //       },
      //       {
      //         text: "Total: 44",
      //         font: {
      //           size: 16,
      //         },
      //       },
      //     ],
      //   },
      legend: {
        display: false,
      },
    },
  };
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Token Allocation</h2>
      <div className={styles.chartContainer}>
        <div className={styles.centerLabel}>
          <p className={styles.label}>{labels[active]}</p>
          <p className={styles.value}>{values[active]}%</p>
        </div>
        <Doughnut data={data} options={chartOptions} />
      </div>

      <div className={styles.legendContainer}>
        {labels.map((el, i) => (
          <p className={styles.legend} onClick={() => setActive(i)} key={i}>
            <span
              className={styles.circle}
              style={{ background: colors[i] }}
            ></span>
            <span> {el}</span>
            <span> {values[i]}%</span>
          </p>
        ))}
      </div>
    </div>
  );
};
export default TokenAllocation;
