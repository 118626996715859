import React from "react";
import styles from "./ExpertField.module.css";

const ExpertField = () => {
  const expertise = [
    "SAAS and B2B integrated services",
    "Automated trading",
    "Fully responsive dashboard and works great on all major devices",
    "Fast deposit and withdrawal",
  ];
  return (
    <div className={styles.wrapper}>
      <div className={[styles.container, "homeWrapper"].join(" ")}>
        <div className={styles.textContainer}>
          <h3 className={styles.title}>We are an expert in this field</h3>
          <p className={styles.info}>
            Yet I feel that I never was a greater artist than now. When, while
            the lovely valley teems.
          </p>
          <div className={styles.expertiseContainer}>
            {expertise.map((el, i) => (
              <p className={styles.expertise} key={i}>
                <span>N</span> {el}
              </p>
            ))}
          </div>
          <button className={styles.button}>Read Our Story</button>
        </div>
        <div className={styles.overlay}></div>
      </div>
    </div>
  );
};

export default ExpertField;
