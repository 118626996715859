import React from "react";
import styles from "./InfoTable.module.css";
import Chart from "./Chart/Chart";

const SingleRow = ({
  assets,
  enableTransfer,
  balance,
  symbol,
  usdBalance,
  price,
  change24h,
  chartData,
  index,
  claim,

  withdraw,
  withdrawlocked,
  depositlocked,
  depositAllowed
}) => {
  const formattedNumber = (
    number,
    showSign = true,
    currency = null,
    minimumFractionDigits = 2
  ) => {
    const sign = showSign ? (number >= 0 ? "+" : "") : "";

    let formattedAmount = number.toFixed(Math.max(minimumFractionDigits, 2));

    if (minimumFractionDigits > 0) {
      formattedAmount = parseFloat(formattedAmount).toFixed(
        minimumFractionDigits
      );
    }

    // Add comma separator
    formattedAmount = parseFloat(formattedAmount).toLocaleString();

    if (currency) {
      const currencyIcon = getCurrencyIcon(currency);
      return currencyIcon + sign + formattedAmount;
    } else {
      return sign + formattedAmount;
    }
  };

  const getCurrencyIcon = (currency) => {
    const currencyIcons = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      // Add more currency codes and their respective icons as needed
    };

    return currencyIcons[currency] || "";
  };

  return (
    <>
      <tr className={[styles.row, styles.valueContainer].join(" ")}>
        <td className={[styles.item, styles.textAlignCenter].join(" ")}>
          {index + 1}
        </td>
        <td className={styles.item}>
          <div className={styles.info}>
            <div className={styles.logoContainer}>
              <img src={assets.logo} alt="#" className={styles.logo} />
            </div>

            <p className={[styles.name, styles.textAlignLeft].join(" ")}>
              {assets.currencyName}
            </p>
          </div>
        </td>
        {/* <td className={styles.item}>
          <p>
            {balance} {symbol}  
            <br />{usdBalance} $ 
          </p>
        </td> */}
        {/* <td className={styles.item}>{formattedNumber(price, false, "USD")}</td> */}
        {/* <td
          className={[
            styles.item,

            change24h > 0
              ? [styles.positiveAmount, styles.amount].join(" ")
              : [styles.negativeAmount, styles.amount].join(" "),
          ].join(" ")}
        >
          {formattedNumber(change24h)}%
        </td> */}
        {/* <td className={[styles.item, styles.chartContainer].join(" ")}>
          {" "}
          <Chart data={chartData.data} color={chartData.color} />
        </td> */}
        <td className={[styles.item, styles.actions].join(" ")}>
          <div className={styles.buttonContainer}>
            {depositAllowed && depositlocked && <button
              disabled
              onClick={() => {
                let data = {
                  currency: assets.currencyName,
                }
                localStorage.setItem("deposit-data", JSON.stringify(data));
                window.location.href = "/deposit";
              }}
              className={[styles.button, styles.lockedButton].join(" ")}>
              Deposit
            </button>}{" "}

            {depositAllowed && !depositlocked && <button
              onClick={() => {
                let data = {
                  currency: assets.currencyName,
                }
                localStorage.setItem("deposit-data", JSON.stringify(data));
                window.location.href = "/deposit";
              }}
              className={[styles.button, styles.lockedButton].join(" ")}>
              Deposit
            </button>}{" "}
            {withdraw && (
              <button
                className={[styles.button, styles.withdrawButton].join(" ")}
                onClick={() => enableTransfer(assets.currencyName, symbol)}
              >
                WITHDRAW
              </button>
            )}
            {withdrawlocked && (
              <button
                disabled
                className={[styles.button, styles.lockedButton].join(" ")}
              >
                Suspended
              </button>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleRow;
