import React from "react";
import styles from "./AboutUs.module.css";
import { aboutUs } from "../../../images/image";

const AboutUs = () => {
  return (
    <div className={styles.wrapper}>
      <div className={[styles.container, "homeWrapper"].join(" ")}>
        <div className={styles.mobileHeader}>
          <p className={styles.tagline}>Our Little Story</p>
          <h3 className={styles.title}>About Us</h3>
          <p className={styles.line}></p>
        </div>
        <img src={aboutUs} alt="#" className={styles.image} />
        <div className={styles.textContainer}>
          <div className={styles.header}>
            <p className={styles.tagline}>Our Little Story</p>
            <h3 className={styles.title}>About Us</h3>
            <p className={styles.line}></p>
          </div>

          <p className={styles.info}>
          Ignite your journey with {process.env.REACT_APP_APP_NAME}, where we provide comprehensive asset management meticulously tailored for a diverse array of crypto projects. Our expertly crafted categorization, extensive investment options, and rock-solid asset protection ensure your investments are poised for optimal returns. <br></br>
 What's more, we offer a spectrum of insurance options meticulously designed to safeguard various crypto projects, delivering not just confidence but the ultimate peace of mind. Seize the moment and join us today to ride the crest of excellence in crypto asset management. Your success begins here!
          </p>
          <button className={styles.button}>Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
